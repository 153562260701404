<template>
    <div class="comment">
        <author
            :avatar="comment.author.image_zoom_out"
            :full-name="comment.author.full_name"
            :link="'/auth/profile/' + comment.author.id"
            :date="comment.created_at"
            class="comments__item"
        />
        <div class="comment__text">
            <span v-if="!isReadMoreActivated && comment.text.length > 300" class="post__text">
                {{comment.text.slice(0, 300)}}...
                <a href="javascript:void(0)" class="v-read-more" @click="isReadMoreActivated = true">
                    Показать полностью
                </a>
            </span>
            <div v-if="isReadMoreActivated || comment.text.length < 300" class="post__text">{{comment.text}}</div>
            <a
                href="javascript:void(0)"
                class="v-link comment__answer"
                @click="commentToHandler(comment)"
            >Ответить</a>

            <div class="comment__thread" v-if="childrenComments.results.length >= 1 || commentTo">
                <ul class="thread__list" v-if="childrenComments.results.length >= 1">
                    <li class="thread__item" v-for="child in childrenComments.results">
                        <author
                            :avatar="child.author.image_zoom_out"
                            :full-name="child.author.full_name"
                            :link="child.author.link"
                            :date="child.created_at"
                            class="child__item"
                        />
                        <div class="thread__text">{{child.text}}</div>
                        <a
                            href="javascript:void(0)"
                            class="v-link comment__answer"
                            @click="commentToHandler(child)"
                        >Ответить</a>
                    </li>
                </ul>
                <!-- Подгрузить комментарии -->
                <div class="thread__more" v-if="childrenComments.count >= 3 && !!nextChildrenComments">
                    <a v-if="!isChildrenCommentsLoading" href="javascript:void(0)" class="thread__more-btn" @click="loadChildrenComments">
                        <i class="thread__more-icon"/>
                        Посмотреть следующие ответы
                    </a>
                    <img v-else class="comments__loading" height="8px" src="../../../assets/animation/loading-comments.gif" alt="Загрузка">
                </div>
                <!-- Ответ на комментарий -->
                <new-comment
                    v-if="commentTo"
                    :content-type="contentType"
                    :obj-id="objId"
                    :on-after-comment="onAfterComment"
                    ref="newComment"

                    :parent-id="comment.id"
                    :comment-to="commentTo"
                    :value="value"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import session from '@/api/session';
    export default {
        name: "Comment",
        components: {
            Author : () => import('../Author'),
            NewComment : () => import('./NewComment')
        },
        data() {
            return {
                isReadMoreActivated: false,
                commentTo: null,
                value: '',
                childrenComments: this.comment.children || [],
                nextChildrenComments: this.comment.children.count > 3 ? `/api/v1/comment/load_for_tree/?contentType=${this.contentType}&id=${this.objId}&comment_id=${this.comment.id}&offset=${3}` : null,
                isChildrenCommentsLoading: false
            }
        },
        props: {
            comment: {
                type: Object,
                required: true
            },
            objId: {
                type: Number,
                required: true
            },
            contentType: {
                type: String,
                required: true
            },
            updateCommentsCount: {
                required: false
            }
        },
        methods: {
            async loadChildrenComments() {
                this.isChildrenCommentsLoading = true;
                try {
                    const request = session.get(this.nextChildrenComments)
                    const response = await request;

                    this.nextChildrenComments = response.data.next;
                    if (response.data.results) {
                        this.childrenComments.results.push(...response.data.results);
                    }
                } catch(error) {
                    this.$swal('Ошибка!', 'При загрузке комментариев произошла ошибка на сервере', 'error');
                    console.error(error)
                }
                this.isChildrenCommentsLoading = false;
            },
            async commentToHandler(comment) {
                this.value = `${comment.author.full_name}, `;
                this.commentTo = comment;
            },
            async onAfterComment(comment) {
                const request = await session.get(`/api/v1/comment/load_for_tree/?contentType=${this.contentType}&id=${this.objId}&comment_id=${this.comment.id}&offset=${this.childrenComments.results.length}`)
                const response = await request;
                this.nextChildrenComments = response.data.next;
                this.childrenComments.results.push(...response.data.results);
                this.commentTo = null;
                this.updateCommentsCount(this.contentType);
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "#sass/v-style";

    .comment {
        .post__text {
            margin-top: 10px;
        }
        .new-comment {
            margin-top: 20px;
        }
        &__text {
            white-space: pre-wrap;
            margin-left: 55px;
            margin-top: 10px;
        }
        &__expand {
            display: flex;
            align-items: center;
        }
        &__icon {

        }
        &__answer {
            display: flex;
            font-size: 0.875rem;
            margin-top: 5px;
        }
        &__thread {
            margin-top: 5px;
            border-top: 1px solid $border-gray;
        }
        .thread {
            &__list {
                list-style: none;
                margin-top: 20px;
            }
            &__item {
                border-bottom: 1px solid $border-gray;
                padding-bottom: 5px;
                margin-top: 15px;
                .comment {
                    &__answer {
                        margin-left: 55px;
                    }
                }
            }
            &__text {
                margin-top: 10px;
                margin-left: 55px;
            }
            &__answer {
                margin-top: 5px;
            }
            &__more {
                margin-top: 10px;
                &-btn {
                    display: flex;
                    align-items: center;
                    color: $link-main;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                &-icon {
                    @include icon($blue-arrow-down, 10px, 13px);
                    margin-right: 5px;
                }
            }
        }
    }
</style>
